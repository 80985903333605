<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { VclList } from "vue-content-loading";
import { required, minLength } from "vuelidate/lib/validators";
import axios from 'axios';
import appConfig from "../../../src/app.config";

export default {
  locales: {
    pt: {
      'Tickets': 'Tickets',
      'Ticket not found.': 'Ticket não encontrato.',
      'Enter your message': 'Digite sua mensagem',
      'Send': 'Enviar',
      'Choose the file': 'Escolha o arquivo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Description is required.': 'Descrição é obrigatório.',
    },
    es: {
      'Tickets': 'Tickets',
      'Ticket not found.': 'Ticket no encontrado.',
      'Enter your message': 'Ingrese su mensaje',
      'Send': 'Enviar',
      'Choose the file': 'Elija el archivo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Description is required.': 'Descripción es obligatoria.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      ticket: {
        loading: true,
        errored: false,
        empty: false,

        id: this.$route.params.id,
        date: '',
        status: '',
        subject: '',
        interactions: ''
      },

      image: null,

      form: {
        loading: false,
        description: ''
      }
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    form: {
      description: { required, minLength: minLength(2) }
    },
  },
  methods: {
    getTicket() {
      api
        .get('ticket/'+this.ticket.id)
        .then(response => {
          if (response.data.status=='success') {
            this.ticket = response.data.ticket
          }
        })
        .catch(error => {
          this.ticket.errored = error
        })
        .finally(() => {
          this.ticket.loading = false
        })
    },
    // ticketSubmit() {
    //   this.form.loading = true
    //   this.$v.form.$touch();

    //   if (!this.$v.form.$error && this.form.description) {
    //     api
    //       .post('ticket/'+this.ticket.id,{
    //         description: this.form.description
    //       })
    //       .then(response => {
    //         if (response.data.status=='success') {
    //           this.form.description = ''
    //           this.$v.form.$reset()

    //           this.ticket.interactions.push({
    //             date: response.data.ticket.date,
    //             type: response.data.ticket.type,
    //             name: response.data.ticket.name,
    //             description: response.data.ticket.description,
    //           })

    //           this.form.loading = false
    //         }
    //       })
    //       .catch(error => {
    //         this.form.errored = error
    //         this.form.loading = false
    //       })
    //       .finally(() => {
    //       })
    //   }

    //   this.form.loading = false
    // },
    uploadImage() {

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { description } = this.form;
        if (description) {  
          this.form.loading = true
          let formData = new FormData();
          formData.append('file', this.file);

          var config = {
            headers: { 
              'x-auth-token': localStorage.token,
              'Content-Type': 'multipart/form-data',
            },
          };

          axios.post(appConfig.apiUrl+'ticket/'+this.ticket.id +'/'+this.form.description + '/' + this.image, formData, config)
            .then((response) => {
            if (response.data.status == 'success') {
              this.getTicket()
              this.form.loading = false
              this.image = null
              this.form.description = ''
              this.file = ''
              this.$v.$reset()
            }
          });
        }
      }
    },
    onFileSelected() {
      this.file = this.$refs.file.files[0];
      this.image = this.file.name
    },
  },
  mounted() {
    this.getTicket()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Tickets') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="ticket.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="ticket.empty">{{ t('No records found.') }}</div>
            <div v-else-if="!ticket.loading && ticket.interactions.length==0">Ticket not found.</div>
            <div v-else class="table-responsive">
              <div class="invoice-title">
                <h4 class="font-size-15 p-1">
                  <div v-if="!ticket.loading">Ticket # {{ this.ticket.id }}</div>
                </h4>
              </div>
              <hr />
              <vcl-list v-if="ticket.loading"></vcl-list>
              <div v-else v-for="(row,index) in ticket.interactions" :key="index">
                <div v-if="row.type === 'affiliate'">
                  <div class="d-flex pr-3 pl-3">
                    <div class="col-lg-1 d-none d-md-block"></div>
                    <div class="col-lg-11 mr-auto p-0">
                      <div class="mb-2 text-right pr-1">
                        <span class="font-weight-bold">{{ row.name }}</span>
                        <img
                          v-if="account.user.avatar"
                          class="rounded-circle header-profile-user ml-2"
                          :src="account.user.avatar"
                          alt=""
                        />
                        <img
                          v-else
                          class="rounded-circle header-profile-user ml-2"
                          src="@/assets/images/logo-orbyy-icon.png"
                          alt=""
                        />
                      <br>
                      </div>
                      
                      <div class="card bg-soft-success m-0 p-3" >
                        <div v-html="row.description"></div>
                      </div>
                      <a class="d-block text-right pt-1 font-size-12" v-if="row.upload" target="_blank" :href="row.upload"><i class="bx bx-file font-size-14"></i> {{row.image}} </a> 
                      <div class="text-right font-size-11 text-muted p-1">{{ row.date }} <i class="mdi mdi-check-all font-size-14 align-middle"></i></div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex pr-3 pl-3">
                    <div class="col-lg-11 mr-auto p-0">
                      <div class="mb-2 pl-1">
                        <img
                          v-if="account.user.avatar"
                          class="rounded-circle header-profile-user mr-2"
                          src="@/assets/images/logo-orbyy-icon.png"
                          alt=""
                        />
                        <span class="font-weight-bold">{{ row.name }}</span><br>
                      </div>
                      <div class="card bg-soft-danger m-0 p-3" v-html="row.description"></div>
                      <div class="font-size-11 text-muted p-1">{{ row.date }}</div>
                    </div>
                    <div class="col-lg-1 d-none d-md-block"></div>
                  </div>
                </div>
                <hr />
              </div>
              <b-form v-if="!ticket.loading" class="p-3" @submit.prevent="uploadImage">
                <b-form-group id="description" :label="t('Enter your message')" label-for="value">
                  <b-form-textarea id="description" v-model="form.description" rows="5" max-rows="6" :class="{ 'is-invalid': $v.form.description.$error }"></b-form-textarea>
                  <div v-if="$v.form.description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.description.required || !$v.form.description.minLength">{{ t('Description is required.') }}</span>
                  </div>
                </b-form-group>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="file">Upload</span>
                      </div>
                      <div class="custom-file" >
                        <input type="file" id="file" ref="file" class="custom-file-input" @change="onFileSelected"
                          aria-describedby="inputGroupFileAddon01">
                        <label class="custom-file-label" for="file" v-if="image == null">{{ t('Choose the file') }}</label>
                        <label class="custom-file-label" for="file" v-else>{{ image }}</label>
                      </div>
                    </div>
                  </div>
                  <b-button  type="submit" variant="primary" :disabled="form.loading">
                    {{ t('Send') }}
                    <b-spinner v-if="form.loading"  small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
              </b-form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>